<template>
  <form ref="ticket">

    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>{{ $t('appointment.component.appointment_request.form.firstname.label') }}:</label>
          <input class="form-control" v-model="value.firstname" required type="text"/>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>{{ $t('appointment.component.appointment_request.form.lastname.label') }}:</label>
          <input class="form-control" v-model="value.lastname" required type="text"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>{{ $t('appointment.component.appointment_request.form.birthdate.label') }}:</label>
          <datepicker :use-utc="true"
                      :bootstrap-styling="true"
                      v-model="value.birthdate" name="birthdate"
                      class="is-invalid border-right-0"
                      :required="true"
                      initial-view="year" :open-date="new Date(1995,1,0)"
                      placeholder="Enter birthdate"
                      :format="'yyyy-MM-dd'" :typeable="true"/>
        </div>
      </div>
    </div>

    <b-row v-if="value.appointment.type !== 'event'">
      <b-col sm="12">
        <div class="form-group">
          <label>{{ $t('appointment.component.appointment_request.form.amount_plants_interested.label') }}</label>
          <select v-model="value.amount_plants_interested" class="form-control" required>
            <option value="0">{{ $t('appointment.component.appointment_request.form.amount_plants_interested.no_plants') }}</option>
            <option value="1">1</option>
            <option value="5">2-5</option>
            <option value="10">5-10</option>
            <option value="20">10-20</option>
            <option value="50">20-50</option>
            <option value="100">50+</option>
          </select>
        </div>
      </b-col>
    </b-row>

    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>{{ $t('appointment.component.appointment_request.form.reason.label') }}:</label>
          <textarea class="form-control" v-model="value.reason" style="min-height: 200px;"/>
        </div>
      </div>
    </div>
  </form>
</template>
<script>


import Datepicker from 'vuejs-datepicker';

export default {
  props: ['value'],
  components: {
    Datepicker
  },
  methods: {
    validate() {
      if (!this.$refs.ticket.checkValidity()) {
        this.$refs.ticket.reportValidity();
        return false;
      } else {
        return true;
      }
    }
  }
}
</script>