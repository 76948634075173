<template>
  <ContentWrapper>
    <breadcrumb/>

    <div class="container">
      <div class="card card-default animated-fast fadeInRight" v-if="ticket">
        <div class="card-header">Edit Ticket {{ ticket.label }}</div>
        <div class="card-body">
          <ticket-registration v-model="ticket" ref="ticketEditor"></ticket-registration>

          <div class="text-right">
            <b-btn @click="submit()" variant="primary">Ticket Registrieren</b-btn>
            <br/>
            <small>Erst nach der Ticket-Registrierung ist dein Ticket gültig. Änderungen sind nicht mehr möglich.</small>
          </div>
        </div>
      </div>
    </div>

  </ContentWrapper>
</template>
<script>

import TicketRegistration from "../components/Actions/TicketRegistration";

export default {
  components: {
    TicketRegistration
  },
  data() {
    return {
      ticket: null
    }
  },
  mounted() {
    this.$api.get('appointment/ticket/' + this.$route.params.label).then(response => {
      this.ticket = response.data;
    })
  },
  methods: {
    submit() {
      if (this.$refs.ticketEditor.validate()) {
        console.log(this.ticket);
        this.$api.post('appointment/ticket/' + this.$route.params.label + '/register', this.ticket).then(response => {
          this.ticket = response.data;
        })
      }
    }
  }
}
</script>